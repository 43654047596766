<template>
  <!-- Accommodation -->
  <section
    class="accommodation position-relative"
    id="accommodation"
    style="background-image: url(images/texture.webp)"
  >
    <img
      class="logo lazyload"
      src="images/logo-gold-20.webp"
      alt="logo_next_hotel"
    />
    <div class="container">
      <div class="position-relative">
        <h2 class="display2 text-dark" v-if="isEnglish">Accommodation</h2>
        <h2 class="display2 text-dark" v-else>Akomodasi</h2>
      </div>
      <div v-if="isLoading" class="skeleton-loading"></div>
      <div
        id="accommodation-carousel"
        class="carousel slide"
        data-bs-ride="carousel"
        v-else
      >
        <div class="carousel-inner" v-if="isEnglish">
          <!-- <div v-for="(data, index) in data.data" :key="index"> -->
          <div
            :class="[index ? 0 : 'active', 'carousel-item']"
            data-bs-interval="5000"
            v-for="(dataEn, index) in dataEn"
            :key="index"
          >
            <div class="row">
              <div class="col-lg-10 g-0 card-accommodation">
                <div class="row g-0 align-items-center">
                  <div class="col-lg-6 accommodation-left">
                    <img
                      class="w-100 lazyload"
                      :src="dataEn.accomodation_image"
                      alt="image_accommodation"
                    />
                  </div>
                  <div class="col-lg-6 accommodation-right">
                    <div class="description">
                      <h2 class="text-dark">{{ dataEn.name }}</h2>
                      <p class="h6 text-dark fw-400 mt-3">
                        {{ dataEn.desc }}
                      </p>
                      <div class="list-icon mt-4">
                        <div
                          v-for="(icon, index) in dataEn.icon_list"
                          :key="index"
                        >
                          <div v-if="icon.icon_text.substring(0, 5) == 'http:'">
                            <img
                              class="lazyload"
                              :src="icon.icon_text"
                              alt="icon_accommodation"
                              width="24px"
                              height="24px"
                            />
                          </div>
                          <div
                            v-else-if="
                              icon.icon_text.substring(0, 5) == 'https'
                            "
                          >
                            <img
                              class="lazyload"
                              :src="icon.icon_text"
                              alt="icon_accommodation"
                              width="24px"
                              height="24px"
                            />
                          </div>
                          <div v-else>
                            <span class="material-icons-round text-dark"
                              >{{ icon.icon_text }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- </div> -->
        </div>
        <div class="carousel-inner" v-else>
          <!-- <div v-for="(data, index) in data.data" :key="index"> -->
          <div
            :class="[index ? 0 : 'active', 'carousel-item']"
            data-bs-interval="5000"
            v-for="(data, index) in data.data"
            :key="index"
          >
            <div class="row">
              <div class="col-lg-10 g-0 card-accommodation">
                <div class="row g-0 align-items-center">
                  <div class="col-lg-6 accommodation-left">
                    <img
                      class="w-100 lazyload"
                      :src="data.accomodation_image"
                      alt="image_accommodation"
                    />
                  </div>
                  <div class="col-lg-6 accommodation-right">
                    <div class="description">
                      <h2 class="text-dark">{{ data.name }}</h2>
                      <p class="h6 text-dark fw-400 mt-3">
                        {{ data.desc }}
                      </p>
                      <div class="list-icon mt-4">
                        <div
                          v-for="(icon, index) in data.icon_list"
                          :key="index"
                        >
                          <div v-if="icon.icon_text.substring(0, 5) == 'http:'">
                            <img
                              class="lazyload"
                              :src="icon.icon_text"
                              alt="icon_accommodation"
                              width="24px"
                              height="24px"
                            />
                          </div>
                          <div
                            v-else-if="
                              icon.icon_text.substring(0, 5) == 'https'
                            "
                          >
                            <img
                              class="lazyload"
                              :src="icon.icon_text"
                              alt="icon_accommodation"
                              width="24px"
                              height="24px"
                            />
                          </div>
                          <div v-else>
                            <span class="material-icons-round text-dark"
                              >{{ icon.icon_text }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- </div> -->
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#accommodation-carousel"
          data-bs-slide="prev"
        >
          <span class="material-icons-round" aria-hidden="true"
            >chevron_left</span
          >
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#accommodation-carousel"
          data-bs-slide="next"
        >
          <span class="material-icons-round" aria-hidden="true"
            >chevron_right</span
          >
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  </section>
  <!-- End Accommodation -->
</template>

<script>
import axios from "axios";

export default {
  name: "AccommodationItem",
  data() {
    return {
      data: [],
      isLoading: true,
      dataEn: [
        {
          id: 2,
          order_id: "1",
          name: "DELUXE",
          desc: "Our convenient Deluxe Room has an area of 32 square meter and equipped with a king bed, a private bathroom with shower, Air Conditioning, LED TV with international channels, safety box, amenities, slippers, mini bar, tea & coffee making, and high-speed internet access.",
          accomodation_image:
            "https://nexthotel.id/cms/storage/media-image/accommodation-deluxe.webp",
          icon_list: [
            {
              id: 25,
              accomodation_id: "2",
              icon_text:
                "https://nexthotel.id/cms/storage/media-image/icon-2deluxe.png",
              created_at: "2023-02-22T06:32:58.000000Z",
              updated_at: "2023-02-22T06:32:58.000000Z",
            },
            {
              id: 26,
              accomodation_id: "2",
              icon_text: "king_bed",
              created_at: "2023-02-22T06:33:09.000000Z",
              updated_at: "2023-02-22T06:33:09.000000Z",
            },
            {
              id: 27,
              accomodation_id: "2",
              icon_text: "ac_unit",
              created_at: "2023-02-22T06:33:20.000000Z",
              updated_at: "2023-02-22T06:33:20.000000Z",
            },
            {
              id: 28,
              accomodation_id: "2",
              icon_text: "tv",
              created_at: "2023-02-22T06:33:27.000000Z",
              updated_at: "2023-02-22T06:33:27.000000Z",
            },
            {
              id: 29,
              accomodation_id: "2",
              icon_text: "wifi",
              created_at: "2023-02-22T06:33:34.000000Z",
              updated_at: "2023-02-22T06:33:34.000000Z",
            },
            {
              id: 30,
              accomodation_id: "2",
              icon_text: "liquor",
              created_at: "2023-02-22T06:33:41.000000Z",
              updated_at: "2023-02-22T06:33:41.000000Z",
            },
          ],
          created_at: "2022-07-20T14:30:54.000000Z",
          updated_at: "2023-02-23T04:19:41.000000Z",
        },
        {
          id: 4,
          order_id: "3",
          name: "Executive",
          desc: "Make sure you and your family enjoy a good night’s sleep in our spacious Executive Room equipped with a king bed, a private bathroom with shower, Air Conditioning, LED TV with international channels, safety box, amenities, slippers, mini bar, tea & coffee making, and high-speed internet access within 36 square meter.",
          accomodation_image:
            "https://nexthotel.id/cms/storage/media-image/accommodation-executive.webp",
          icon_list: [
            {
              id: 31,
              accomodation_id: "4",
              icon_text:
                "https://nexthotel.id/cms/storage/media-image/icon-3executive.png",
              created_at: "2023-02-22T06:33:57.000000Z",
              updated_at: "2023-02-22T06:33:57.000000Z",
            },
            {
              id: 32,
              accomodation_id: "4",
              icon_text: "king_bed",
              created_at: "2023-02-22T06:34:11.000000Z",
              updated_at: "2023-02-22T06:34:11.000000Z",
            },
            {
              id: 33,
              accomodation_id: "4",
              icon_text: "ac_unit",
              created_at: "2023-02-22T06:34:19.000000Z",
              updated_at: "2023-02-22T06:34:19.000000Z",
            },
            {
              id: 34,
              accomodation_id: "4",
              icon_text: "tv",
              created_at: "2023-02-22T06:34:27.000000Z",
              updated_at: "2023-02-22T06:34:27.000000Z",
            },
            {
              id: 35,
              accomodation_id: "4",
              icon_text: "wifi",
              created_at: "2023-02-22T06:34:34.000000Z",
              updated_at: "2023-02-22T06:34:34.000000Z",
            },
            {
              id: 36,
              accomodation_id: "4",
              icon_text: "liquor",
              created_at: "2023-02-22T06:34:44.000000Z",
              updated_at: "2023-02-22T06:34:44.000000Z",
            },
          ],
          created_at: "2023-02-15T15:36:51.000000Z",
          updated_at: "2023-02-22T06:27:55.000000Z",
        },
        {
          id: 5,
          order_id: "4",
          name: "Suite",
          desc: "Take your stay to the next level with our Suite Room. This large 68 square meter sized room is ideal for larger families or business professionals and equipped with a king bed, sofa, and working desk. This room is equipped with a private bathroom with shower, modern furniture, Air Conditioning, LED TV with international channels, safety box, amenities, slippers, mini bar, tea & coffee making, and high-speed internet access.",
          accomodation_image:
            "https://nexthotel.id/cms/storage/media-image/accommodation-suite.webp",
          icon_list: [
            {
              id: 37,
              accomodation_id: "5",
              icon_text:
                "https://nexthotel.id/cms/storage/media-image/icon-4suite.png",
              created_at: "2023-02-22T06:38:23.000000Z",
              updated_at: "2023-02-22T06:38:23.000000Z",
            },
            {
              id: 38,
              accomodation_id: "5",
              icon_text: "king_bed",
              created_at: "2023-02-22T06:38:34.000000Z",
              updated_at: "2023-02-22T06:38:34.000000Z",
            },
            {
              id: 39,
              accomodation_id: "5",
              icon_text: "ac_unit",
              created_at: "2023-02-22T06:38:42.000000Z",
              updated_at: "2023-02-22T06:38:42.000000Z",
            },
            {
              id: 40,
              accomodation_id: "5",
              icon_text: "chair",
              created_at: "2023-02-22T06:38:58.000000Z",
              updated_at: "2023-02-22T06:38:58.000000Z",
            },
            {
              id: 41,
              accomodation_id: "5",
              icon_text: "desk",
              created_at: "2023-02-22T06:39:09.000000Z",
              updated_at: "2023-02-22T06:39:09.000000Z",
            },
            {
              id: 42,
              accomodation_id: "5",
              icon_text: "bathtub",
              created_at: "2023-02-22T06:39:26.000000Z",
              updated_at: "2023-02-22T06:39:26.000000Z",
            },
            {
              id: 43,
              accomodation_id: "5",
              icon_text: "tv",
              created_at: "2023-02-22T06:39:35.000000Z",
              updated_at: "2023-02-22T06:39:35.000000Z",
            },
            {
              id: 44,
              accomodation_id: "5",
              icon_text: "wifi",
              created_at: "2023-02-22T06:39:45.000000Z",
              updated_at: "2023-02-22T06:39:45.000000Z",
            },
            {
              id: 45,
              accomodation_id: "5",
              icon_text: "liquor",
              created_at: "2023-02-22T06:40:06.000000Z",
              updated_at: "2023-02-22T06:40:06.000000Z",
            },
          ],
          created_at: "2023-02-15T15:39:41.000000Z",
          updated_at: "2023-02-27T01:44:29.000000Z",
        },
        {
          id: 3,
          order_id: "5",
          name: "Superior",
          desc: "The compact yet comfortable Superior Room is suitable for your short staycation or business travel. This 18 square meter sized room is equipped with a choice of twin-bed or king-size bed, a private bathroom with shower, Air Conditioning, LED TV with international channels, safety box, amenities, slippers, mini bar, tea & coffee making, and high-speed internet access.",
          accomodation_image:
            "https://nexthotel.id/cms/storage/media-image/accommodation-superior.webp",
          icon_list: [
            {
              id: 19,
              accomodation_id: "3",
              icon_text:
                "https://nexthotel.id/cms/storage/media-image/icon-1superior.png",
              created_at: "2023-02-22T06:30:13.000000Z",
              updated_at: "2023-02-22T06:30:13.000000Z",
            },
            {
              id: 20,
              accomodation_id: "3",
              icon_text: "king_bed",
              created_at: "2023-02-22T06:31:26.000000Z",
              updated_at: "2023-02-22T06:31:26.000000Z",
            },
            {
              id: 21,
              accomodation_id: "3",
              icon_text: "ac_unit",
              created_at: "2023-02-22T06:31:39.000000Z",
              updated_at: "2023-02-22T06:31:39.000000Z",
            },
            {
              id: 22,
              accomodation_id: "3",
              icon_text: "tv",
              created_at: "2023-02-22T06:31:46.000000Z",
              updated_at: "2023-02-22T06:31:46.000000Z",
            },
            {
              id: 23,
              accomodation_id: "3",
              icon_text: "wifi",
              created_at: "2023-02-22T06:31:55.000000Z",
              updated_at: "2023-02-22T06:31:55.000000Z",
            },
            {
              id: 24,
              accomodation_id: "3",
              icon_text: "liquor",
              created_at: "2023-02-22T06:32:04.000000Z",
              updated_at: "2023-02-22T06:32:04.000000Z",
            },
          ],
          created_at: "2022-11-29T13:26:25.000000Z",
          updated_at: "2023-02-23T04:18:47.000000Z",
        },
      ],
    };
  },
  methods: {
    setData(data) {
      this.data = data;
      this.isLoading = false;
    },
  },
  mounted() {
    // Check if data is already in local storage
    const accomodationData = localStorage.getItem("accomodationData");

    if (accomodationData) {
      // Use stored data if available
      this.setData(JSON.parse(accomodationData));
    } else {
      // If not available, make API call
      axios
        .get("https://nexthotel.id/cms/api/accomodation-api")
        .then((res) => {
          this.setData(res.data);

          // Store the data in local storage for future use
          localStorage.setItem("accomodationData", JSON.stringify(res.data));
        })
        .catch((error) => {
          // handle error
          console.log("Gagal", error);
          this.isLoading = false;
        });
    }
  },
  computed: {
    isEnglish() {
      return (
        this.$route.path.includes("/en") || this.$route.path.includes("/en/")
      );
    },
  },
};
</script>

<style scoped>
.skeleton-loading {
  width: 100%;
  height: 100vh;
  background: linear-gradient(90deg, #f0f0f0 25%, #d0d0d0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: gradient-slide 1.5s infinite;
}

@keyframes gradient-slide {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
</style>
