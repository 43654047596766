<template>
  <!-- Facilities -->
  <section class="facilities" id="facilities">
    <img class="logo" src="images/logo-white-20.webp" alt="logo_white" />
    <h2 class="display2 text-dark" v-if="isEnglish">Facilities</h2>
    <h2 class="display2 text-dark" v-else>Fasilitas</h2>
    <div v-if="isLoading" class="skeleton-loading"></div>
    <div v-else>
      <div class="slider desktop-slider-facilities" v-if="isEnglish">
        <Carousel
          v-if="dataEn.length !== 0"
          :items-to-show="2.5"
          :wrap-around="true"
          v-model="currentSlide"
        >
          <Slide v-for="(dataEn, index) in dataEn" :key="index">
            <img :src="dataEn.facility_image" alt="image_accommodation" />
            <div class="description">
              <h1 class="text-white text-uppercase">{{ dataEn.name }}</h1>
              <h6 class="fw-400 text-white">
                {{ dataEn.desc }}
              </h6>
              <a
                v-if="
                  dataEn.id === 2 ||
                  dataEn.name === 'AHA! Café' ||
                  dataEn.name === 'AHA! CAFÉ'
                "
                style="
                  background-color: #dec4a1;
                  margin-top: 14px;
                  font-size: 12px;
                  padding: 4px 12px;
                "
                href="https://drive.google.com/file/d/1tx4Jgn-n0uJ6EauegzHnLRp38gKKMnug/view?usp=sharing"
                class="btn"
                target="_blank"
                >View Menu</a
              >
            </div>
          </Slide>

          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
      <div class="slider desktop-slider-facilities" v-else>
        <Carousel
          v-if="data.length !== 0"
          :items-to-show="2.5"
          :wrap-around="true"
          v-model="currentSlide"
        >
          <Slide v-for="(data, index) in data.data" :key="index">
            <img :src="data.facility_image" alt="image_accommodation" />
            <div class="description">
              <h1 class="text-white text-uppercase">{{ data.name }}</h1>
              <h6 class="fw-400 text-white">
                {{ data.desc }}
              </h6>
              <a
                v-if="
                  data.id === 4221 ||
                  data.name === 'AHA! Café' ||
                  data.name === 'AHA! CAFÉ'
                "
                style="
                  background-color: #dec4a1;
                  margin-top: 14px;
                  font-size: 12px;
                  padding: 4px 12px;
                "
                href="https://drive.google.com/file/d/1tx4Jgn-n0uJ6EauegzHnLRp38gKKMnug/view?usp=sharing"
                class="btn"
                target="_blank"
                >Lihat Menu</a
              >
            </div>
          </Slide>

          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
      <div class="slider mobile-slider-facilities" v-if="isEnglish">
        <Carousel
          v-if="data.length !== 0"
          :items-to-show="1"
          :wrap-around="true"
          v-model="currentSlide"
        >
          <Slide v-for="(dataEn, index) in dataEn" :key="index">
            <img :src="dataEn.facility_image" alt="image_facility" />
            <div class="description" style="padding: 0 70px 20px">
              <h1 class="text-white text-uppercase">{{ dataEn.name }}</h1>
              <h6 class="fw-400 text-white">
                {{ dataEn.desc }}
              </h6>
              <a
                v-if="
                  dataEn.id === 2 ||
                  dataEn.name === 'AHA! Café' ||
                  dataEn.name === 'AHA! CAFÉ'
                "
                style="background-color: #dec4a1; margin-top: 14px"
                href="https://drive.google.com/file/d/1tx4Jgn-n0uJ6EauegzHnLRp38gKKMnug/view?usp=sharing"
                class="btn"
                target="_blank"
                data-v-e06cbd22=""
                >View Menu</a
              >
            </div>
          </Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
      <div class="slider mobile-slider-facilities" v-else>
        <Carousel
          v-if="data.length !== 0"
          :items-to-show="1"
          :wrap-around="true"
          v-model="currentSlide"
        >
          <Slide v-for="(data, index) in data.data" :key="index">
            <img :src="data.facility_image" alt="image_facility" />
            <div class="description" style="padding: 0 70px 20px">
              <h1 class="text-white text-uppercase">{{ data.name }}</h1>
              <h6 class="fw-400 text-white">
                {{ data.desc }}
              </h6>
              <a
                v-if="
                  data.id === 4221 ||
                  data.name === 'AHA! Café' ||
                  data.name === 'AHA! CAFÉ'
                "
                style="background-color: #dec4a1; margin-top: 14px"
                href="https://drive.google.com/file/d/1tx4Jgn-n0uJ6EauegzHnLRp38gKKMnug/view?usp=sharing"
                class="btn"
                target="_blank"
                data-v-e06cbd22=""
                >Lihat Menu</a
              >
            </div>
          </Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
    </div>
  </section>
  <!-- End Facilities -->
</template>

<script>
import axios from "axios";
import { defineComponent } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";
export default defineComponent({
  name: "FacilitiesItem",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      data: [],
      isLoading: true,
      currentSlide: 0,
      dataEn: [
        {
          id: 1,
          order_id: 1,
          name: "RESTAURANT",
          desc: "Enjoy a wide selection of special Indonesian, Asian, and  western cuisine at Kembang Arum Restaurant.",
          facility_image:
            "https://nexthotel.id/cms/storage/media-image/03ARestaurant.jpg",
        },
        {
          id: 2,
          order_id: 2,
          name: "AHA! CAFÉ",
          desc: "AHA! Café on the ground floor is your next favorite place to hang out and relax with your friends or communities while enjoying a variety of delicious snacks and drinks.",
          facility_image:
            "https://nexthotel.id/cms/storage/media-image/03BAHACafe.jpg",
        },
        {
          id: 3,
          order_id: 3,
          name: "MEETING ROOM",
          desc: "There are 5 meeting rooms to meet your specific business needs, start from a smaller one on the Ground floor with capacity of 10 pax to the function room on the 3rd floor with capacity of 250 pax, each room is equipped with AC, LCD Projector, Smart TV, and Conference Camera.",
          facility_image:
            "https://nexthotel.id/cms/storage/media-image/03CMeeting.jpg",
        },
        {
          id: 4,
          order_id: 4,
          name: "WEDDING AREA",
          desc: "Celebrate your intimate wedding moment at our modern function room or outdoor pool area that can accommodate up to 250 people.",
          facility_image:
            "https://nexthotel.id/cms/storage/media-image/03DWedding.jpg",
        },
        {
          id: 5,
          order_id: 5,
          name: "SWIMMING POOL",
          desc: "Our outdoor swimming pool is surrounded by lush greenery. A perfect choice for guests to keep cool in the heat outside during their staycation.",
          facility_image:
            "https://nexthotel.id/cms/storage/media-image/03EPool.jpg",
        },
        {
          id: 6,
          order_id: 6,
          name: "SPA",
          desc: "Pamper yourself with our selection of rejuvenating javanese massage and other spa treatments.",
          facility_image:
            "https://nexthotel.id/cms/storage/media-image/03FSpa.jpg",
        },
      ],
    };
  },
  methods: {
    setData(data) {
      this.data = data;
      this.isLoading = false;
    },
  },
  mounted() {
    // Check if data is already in local storage
    const facilityData = localStorage.getItem("facilityData");

    if (facilityData) {
      // Use stored data if available
      this.setData(JSON.parse(facilityData));
    } else {
      // If not available, make API call
      axios
        .get("https://nexthotel.id/cms/api/facility-api")
        .then((res) => {
          this.setData(res.data);

          // Store the data in local storage for future use
          localStorage.setItem("facilityData", JSON.stringify(res.data));
        })
        .catch((error) => {
          // handle error
          console.log("Gagal", error);
          this.isLoading = false;
        });
    }
  },
  computed: {
    isEnglish() {
      return (
        this.$route.path.includes("/en") || this.$route.path.includes("/en/")
      );
    },
  },
});
</script>

<style scoped>
.facilities .carousel .carousel__slide.carousel__slide--active .description h6 {
  font-size: 10px !important;
  line-height: 15px !important;
}
.description h6 {
  font-size: 10px !important;
  line-height: 15px !important;
}

.facilities .carousel .carousel__slide.carousel__slide--active .description h1 {
  font-size: 24px !important;
}

.description h1 {
  font-size: 24px !important;
}

@media screen and (max-width: 576px) {
  .desktop-slider-facilities {
    display: none !important;
  }
  .mobile-slider-facilities {
    display: block !important;
  }

  .carousel .btn {
    transform: scale(0.7);
    transform-origin: left top;
  }
}

@media screen and (min-width: 576px) {
  .desktop-slider-facilities {
    display: block !important;
  }
  .mobile-slider-facilities {
    display: none !important;
  }
}

.skeleton-loading {
  width: 80%;
  height: 100vh;
  margin: auto;
  background: linear-gradient(90deg, #f0f0f0 25%, #d0d0d0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: gradient-slide 1.5s infinite;
}

@keyframes gradient-slide {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
</style>