<template>
  <!-- Page Not Found (404) -->
  <section
    class="page-not-found"
    style="background-image: url(images/texture.jpg)"
  >
    <img class="logo" src="images/logo.png" alt="Logo" />
    <img class="decoration" src="images/logo-gold-20.png" alt="Logo" />
    <div class="content">
      <div>
        <img src="images/404.png" alt="404" />
        <h1>something’s missing</h1>
        <h6>The page is missing or you assembled the link incorrectly</h6>
        <a href="/" class="btn btn-primary"
          ><span class="material-icons-round">west</span
          ><span class="btn-ms">Back to Home</span></a
        >
      </div>
    </div>
  </section>
  <!-- End Page Not Found (404) -->
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>