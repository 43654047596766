<template>
  <!-- Local Area -->
  <section class="local-area position-relative" id="local-area">
    <img
      class="logo lazyload"
      src="images/logo-gold-20.webp"
      alt="logo_gold_next_hotel"
    />
    <div class="container">
      <h2 class="display2" v-if="isEnglish">Local Area</h2>
      <h2 class="display2" v-else>Area Lokal</h2>
      <div class="row gx-0 gy-4 mt-6 justify-content-between">
        <div class="col-lg-6">
          <h3 class="text-dark text-center mx-auto" v-if="isEnglish">
            AROUND THE PROPERTY
          </h3>
          <h3 class="text-dark text-center mx-auto" v-else>SEKITAR HOTEL</h3>
          <div v-if="isLoading" class="skeleton-loading"></div>
          <div v-else>
            <ul
              class="list-group list-group-flush list-left mt-5"
              v-if="isEnglish"
            >
              <li
                v-for="(dataEn, index) in data_aroundEn"
                :key="index"
                class="list-group-item d-flex align-items-center justify-content-between"
              >
                <div class="d-flex align-items-center">
                  <span class="material-icons-round">{{ dataEn.icon }}</span>
                  <div class="ms-3">
                    <h6 class="fw-600">{{ dataEn.name }}</h6>
                    <span class="subtitle text-secondary">{{
                      dataEn.desc
                    }}</span>
                  </div>
                </div>
                <span class="badge h6">{{ dataEn.km }} km</span>
              </li>
            </ul>
            <ul class="list-group list-group-flush list-left mt-5" v-else>
              <li
                v-for="(data, index) in data_around.data"
                :key="index"
                class="list-group-item d-flex align-items-center justify-content-between"
              >
                <div class="d-flex align-items-center">
                  <span class="material-icons-round">{{ data.icon }}</span>
                  <div class="ms-3">
                    <h6 class="fw-600">{{ data.name }}</h6>
                    <span class="subtitle text-secondary">{{ data.desc }}</span>
                  </div>
                </div>
                <span class="badge h6">{{ data.km }} km</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6">
          <h3 class="text-dark text-center mx-auto" v-if="isEnglish">
            POPULAR IN THIS AREA
          </h3>
          <h3 class="text-dark text-center mx-auto" v-else>TEMPAT POPULER</h3>
          <div v-if="isLoading" class="skeleton-loading"></div>
          <div v-else>
            <ul
              class="list-group list-group-flush list-right mt-5"
              v-if="isEnglish"
            >
              <li
                v-for="(dataEn, index) in data_popularEn"
                :key="index"
                class="list-group-item d-flex align-items-center justify-content-between"
              >
                <div class="d-flex align-items-center">
                  <span class="material-icons-round">{{ dataEn.icon }}</span>
                  <div class="ms-3">
                    <h6 class="fw-600">{{ dataEn.name }}</h6>
                    <span class="subtitle text-secondary">{{
                      dataEn.desc
                    }}</span>
                  </div>
                </div>
                <span class="badge h6">{{ dataEn.km }} km</span>
              </li>
            </ul>
            <ul class="list-group list-group-flush list-right mt-5" v-else>
              <li
                v-for="(data, index) in data_popular.data"
                :key="index"
                class="list-group-item d-flex align-items-center justify-content-between"
              >
                <div class="d-flex align-items-center">
                  <span class="material-icons-round">{{ data.icon }}</span>
                  <div class="ms-3">
                    <h6 class="fw-600">{{ data.name }}</h6>
                    <span class="subtitle text-secondary">{{ data.desc }}</span>
                  </div>
                </div>
                <span class="badge h6">{{ data.km }} km</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Local Area -->
</template>

<script>
import axios from "axios";

export default {
  name: "LocalAreaItem",

  data() {
    return {
      isLoading: true,
      data_around: [],
      data_popular: [],
      data_aroundEn: [
        {
          id: 10,
          name: "Restaurant & Cafe at Next Hotel",
          desc: "Casual Dining",
          icon: "local_dining",
          km: "0",
          category: "around",
          created_at: "2023-01-25T15:19:06.000000Z",
          updated_at: "2023-02-15T15:50:53.000000Z",
        },
        {
          id: 13,
          name: "UPN Veteran Yogyakarta",
          desc: "National University",
          icon: "school",
          km: "0.6",
          category: "around",
          created_at: "2023-02-13T11:00:30.000000Z",
          updated_at: "2023-02-22T08:53:43.000000Z",
        },
        {
          id: 15,
          name: "Ambarukmo Plaza",
          desc: "Shopping Center",
          icon: "storefront",
          km: "1",
          category: "around",
          created_at: "2023-01-25T15:19:06.000000Z",
          updated_at: "2023-02-15T15:51:09.000000Z",
        },
        {
          id: 22,
          name: "Pakuwon Mall",
          desc: "Shopping Center",
          icon: "storefront",
          km: "2",
          category: "around",
          created_at: "2023-02-13T11:00:30.000000Z",
          updated_at: "2023-11-23T06:09:59.000000Z",
        },
        {
          id: 23,
          name: "Transmart Carefour Maguwoharjo",
          desc: "Shopping Center",
          icon: "local_mall",
          km: "3",
          category: "around",
          created_at: "2023-02-13T11:00:30.000000Z",
          updated_at: "2023-02-15T15:51:19.000000Z",
        },
        {
          id: 24,
          name: "Adisucipto International Airport",
          desc: "Regional Public Airport",
          icon: "local_airport",
          km: "4",
          category: "around",
          created_at: "2023-01-25T15:19:06.000000Z",
          updated_at: "2023-02-15T15:51:25.000000Z",
        },
        {
          id: 28,
          name: "Hermina Hospital Yogya",
          desc: "Regional Public Hospital",
          icon: "local_hospital",
          km: "5",
          category: "around",
          created_at: "2023-01-25T15:19:06.000000Z",
          updated_at: "2023-02-15T15:51:38.000000Z",
        },
        {
          id: 29,
          name: "SPBU Pertamina Maguwoharjo",
          desc: "Gas Station",
          icon: "local_gas_station",
          km: "5",
          category: "around",
          created_at: "2023-01-25T15:19:06.000000Z",
          updated_at: "2023-02-15T15:51:46.000000Z",
        },
      ],
      data_popularEn: [
        {
          id: 9,
          name: "Waterboom Jogja",
          desc: "Water Park",
          icon: "pool",
          km: "1",
          category: "popular",
          created_at: "2023-01-25T14:18:02.000000Z",
          updated_at: "2023-11-23T06:10:13.000000Z",
        },
        {
          id: 11,
          name: "Gudeg Yu Djum",
          desc: "Culinary Tourism",
          icon: "local_dining",
          km: "1",
          category: "popular",
          created_at: "2023-01-26T14:10:33.000000Z",
          updated_at: "2023-02-22T08:38:26.000000Z",
        },
        {
          id: 14,
          name: "Museum Dirgantara Mandala",
          desc: "Museum AU Tourism",
          icon: "museum",
          km: "2",
          category: "popular",
          created_at: "2023-02-13T11:08:51.000000Z",
          updated_at: "2023-02-22T08:38:40.000000Z",
        },
        {
          id: 16,
          name: "Jalan Malioboro",
          desc: "Shopping Tourism",
          icon: "local_mall",
          km: "6",
          category: "popular",
          created_at: "2023-01-25T14:18:02.000000Z",
          updated_at: "2023-02-22T08:38:50.000000Z",
        },
        {
          id: 17,
          name: "Stadion Maguwoharjo",
          desc: "Sport Center",
          icon: "stadium",
          km: "8",
          category: "popular",
          created_at: "2023-01-25T14:18:02.000000Z",
          updated_at: "2023-02-22T08:39:02.000000Z",
        },
        {
          id: 18,
          name: "Candi Ratu Boko",
          desc: "Cultural Tourism",
          icon: "location_city",
          km: "8",
          category: "popular",
          created_at: "2023-01-25T14:18:02.000000Z",
          updated_at: "2023-02-22T08:39:15.000000Z",
        },
        {
          id: 19,
          name: "Kampung Wisata Taman Sari",
          desc: "Cultural Tourism",
          icon: "location_city",
          km: "10",
          category: "popular",
          created_at: "2023-01-25T14:18:02.000000Z",
          updated_at: "2023-02-22T08:39:18.000000Z",
        },
        {
          id: 20,
          name: "Candi Prambanan",
          desc: "Cultural Tourism",
          icon: "location_city",
          km: "10",
          category: "popular",
          created_at: "2023-01-25T14:18:02.000000Z",
          updated_at: "2023-02-22T08:39:20.000000Z",
        },
      ],
    };
  },
  methods: {
    setDataAround(data) {
      this.data_around = data;
      this.isLoading = false;
    },
    setDataPopular(data) {
      this.data_popular = data;
      this.isLoading = false;
    },
  },
  mounted() {
    // Check if data for "local-area-around-api" is already in local storage
    const aroundData = localStorage.getItem("aroundData");

    if (aroundData) {
      // Use stored data if available
      this.setDataAround(JSON.parse(aroundData));
    } else {
      // If not available, make API call
      axios
        .get("https://nexthotel.id/cms/api/local-area-around-api")
        .then((res) => {
          this.setDataAround(res.data);

          // Store the data in local storage for future use
          localStorage.setItem("aroundData", JSON.stringify(res.data));
        })
        .catch((error) => {
          // handle error
          console.log("Gagal", error);
          this.isLoading = false;
        });
    }

    // Check if data for "local-area-popular-api" is already in local storage
    const popularData = localStorage.getItem("popularData");

    if (popularData) {
      // Use stored data if available
      this.setDataPopular(JSON.parse(popularData));
    } else {
      // If not available, make API call
      axios
        .get("https://nexthotel.id/cms/api/local-area-popular-api")
        .then((res) => {
          this.setDataPopular(res.data);

          // Store the data in local storage for future use
          localStorage.setItem("popularData", JSON.stringify(res.data));
        })
        .catch((error) => {
          // handle error
          console.log("Gagal", error);
          this.isLoading = false;
        });
    }
  },
  computed: {
    isEnglish() {
      return (
        this.$route.path.includes("/en") || this.$route.path.includes("/en/")
      );
    },
  },
};
</script>

<style scoped>
.skeleton-loading {
  width: 100%;
  height: 100vh;
  background: linear-gradient(90deg, #f0f0f0 25%, #d0d0d0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: gradient-slide 1.5s infinite;
}

@keyframes gradient-slide {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
</style>
