<template>
  <section
    class="socmed-gallery position-relative"
    id="social"
    style="background-image: url(/images/texture.webp)"
  >
    <img
      class="logo lazyload"
      src="/images/logo-gold-20.webp"
      alt="logo_gold_next_hotel"
    />
    <div class="divider position-absolute"></div>
    <div class="divider position-absolute"></div>
    <div class="container">
      <h2 class="display2" v-if="isEnglish">Social Media Gallery</h2>
      <h2 class="display2" v-else>Galeri Sosial Media</h2>
      <span class="h5 d-block text-center" v-if="isEnglish"
        >Follow Us on Instagram: @next.hotel.id</span
      >
      <span class="h5 d-block text-center" v-else
        >Ikuti Instagram Kami: @next.hotel.id</span
      >
      <div v-if="isLoading" class="skeleton-loading"></div>
      <div
        v-html="data.embed_code_ig"
        title="embed_ig"
        class="embed_ig mt-4"
        v-else
      />
    </div>
  </section>
  <!-- Dynamic From  -->
</template>

<script>
import axios from "axios";

export default {
  name: "SocialMediaGalleryItem",

  data() {
    return {
      data: [],
      isLoading: true,
    };
  },
  methods: {
    setData(data) {
      this.data = data;
      this.isLoading = false;
    },
  },
  mounted() {
    // Check if data for "social-media-api" is already in local storage
    const socialMediaData = localStorage.getItem("socialMediaData");

    if (socialMediaData) {
      // Use stored data if available
      this.setData(JSON.parse(socialMediaData));
    } else {
      // If not available, make API call
      axios
        .get("https://nexthotel.id/cms/api/social-media-api")
        .then((res) => {
          this.setData(res.data.data);

          // Store the data in local storage for future use
          localStorage.setItem(
            "socialMediaData",
            JSON.stringify(res.data.data)
          );
        })
        .catch((error) => {
          // handle error
          console.log("Gagal", error);
          this.isLoading = false;
        });
    }
  },
  computed: {
    isEnglish() {
      return (
        this.$route.path.includes("/en") || this.$route.path.includes("/en/")
      );
    },
  },
};
</script>
<style>
.embed_ig iframe {
  height: 400px;
}
</style>
