<template>
  <!-- Hero -->
  <section class="hero">
    <keep-alive>
      <div v-if="isLoading || data.length === 0" class="skeleton-loading"></div>
      <div v-else>
        <img
          class="background w-100 lazyload"
          :src="data[0].hero_image"
          alt="hero_image "
        />
        <div class="overlay"></div>
        <div class="title">
          <div class="container">
            <h1 v-html="data[0].name" class="display2 text-uppercase"></h1>
          </div>
        </div>
      </div>
    </keep-alive>
  </section>
  <!-- End Hero -->
</template>

<script>
import axios from "axios";

export default {
  name: "HeroItem",

  data() {
    return {
      data: [],
      isLoading: true,
      logo: "",
      modal_book: false,
      showNavbar: false,
    };
  },
  methods: {
    setData(data) {
      this.data = data;
      this.isLoading = false;
    },
    showModalBook() {
      this.modal_book = !this.modal_book;
    },
    showForNavbar() {
      this.showNavbar = !this.showNavbar;
    },
  },
  mounted() {
    // Check if data is already in local storage
    const heroData = localStorage.getItem("heroData");

    if (heroData) {
      // Use stored data if available
      this.setData(JSON.parse(heroData));
    } else {
      // If not available, make API call
      axios
        .get("https://nexthotel.id/cms/api/hero-api")
        .then((res) => {
          this.setData(res.data.data);

          // Store the data in local storage for future use
          localStorage.setItem("heroData", JSON.stringify(res.data.data));
        })
        .catch(function (error) {
          console.log("Gagal", error);
          this.isLoading = false;
        });
    }
  },
};
</script>

<style scoped>
.navbar {
  background-image: url("https://nexthotel.id/images/texture.webp") !important;
  z-index: 3;
}

.collapse .navbar-nav {
  align-items: center !important;
}

@media (max-width: 576px) {
  .collapse .navbar-nav {
    align-items: flex-start !important;
  }
}

.skeleton-loading {
  width: 100%;
  height: 100vh;
  background: linear-gradient(90deg, #f0f0f0 25%, #d0d0d0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: gradient-slide 1.5s infinite;
}

@keyframes gradient-slide {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
</style>
