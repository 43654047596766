<template>
  <!-- Story -->

  <section
    class="story position-relative"
    style="background-image: url(images/texture.webp)"
    id="story"
  >
    <img class="logo" src="images/logo-gold-20.webp" alt="logo_mnc_gold" />
    <div class="container">
      <div v-if="isLoading || data.length === 0" class="skeleton-loading"></div>
      <div v-else>
        <div class="row align-items-center">
          <div class="col-lg-7">
            <div class="position-relative overflow-hidden">
              <img
                :src="data[0].story_image"
                class="lazyload"
                alt="image_story"
                @load="isLoading = false"
              />
            </div>
          </div>
          <div class="col-lg-5">
            <div class="description">
              <h2 class="text-dark text-uppercase">
                <div v-if="isEnglish">DISCOVER THE DIFFERENCE</div>
                <div v-html="data[0].name" v-else></div>
              </h2>
              <p class="h6 text-dark fw-400 mt-3" v-if="isEnglish">
                Find a special experience only at Next Hotel, one of the best
                4-star hotels in Yogyakarta. The hotel consists of 12 floors
                with 127 rooms & suites equipped with various facilities to take
                both your business and leisure travel to the
                <em>next level</em>, such as function room, meeting rooms,
                restaurant, sports bar, swimming pool, spa, and also integrated
                with a modern apartment
              </p>
              <p
                v-html="data[0].desc"
                class="h6 text-dark fw-400 mt-3"
                v-else
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Story -->
</template>

<script>
import VLazyImage from "v-lazy-image";
import axios from "axios";

export default {
  name: "StoryItem",
  components: {
    VLazyImage,
  },
  data() {
    return {
      data: [],
      isLoading: true,
    };
  },
  methods: {
    setData(data) {
      this.data = data;
      this.isLoading = false;
    },
  },
  mounted() {
    // Check if data is already in local storage
    const storyData = localStorage.getItem("storyData");

    if (storyData) {
      // Use stored data if available
      this.setData(JSON.parse(storyData));
    } else {
      // If not available, make API call
      axios
        .get("https://nexthotel.id/cms/api/story-api")
        .then((res) => {
          this.setData(res.data.data);

          // Store the data in local storage for future use
          localStorage.setItem("storyData", JSON.stringify(res.data.data));
        })
        .catch(function (error) {
          console.log("Gagal", error);
          this.isLoading = false;
        });
    }
  },
  computed: {
    isEnglish() {
      return (
        this.$route.path.includes("/en") || this.$route.path.includes("/en/")
      );
    },
  },
};
</script>

<style scoped>
.skeleton-loading {
  width: 100%;
  height: 100vh;
  background: linear-gradient(90deg, #f0f0f0 25%, #d0d0d0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: gradient-slide 1.5s infinite;
}

@keyframes gradient-slide {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
</style>
