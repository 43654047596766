<template>
  <div class="home">
    <NavbarItem />
    <HeroItem />
    <StoryItem />
    <FacilitiesItem />
    <AccommodationItem />
    <MiceItem />
    <LocalAreaItem />
    <SocialMediaGalleryItem />
    <ContactItem />
    <FooterItem />
  </div>
</template>

<script>
import NavbarItem from "@/components/layout/Navbar.vue";
import HeroItem from "@/components/section/Hero.vue";
import StoryItem from "@/components/section/Story.vue";
import FacilitiesItem from "@/components/section/Facilities.vue";
import AccommodationItem from "@/components/section/Accommodation.vue";
import MiceItem from "@/components/section/Mice.vue";
import LocalAreaItem from "@/components/section/Local-Area.vue";
import ContactItem from "@/components/section/Contact.vue";
import SocialMediaGalleryItem from "@/components/section/Social-Media-Gallery.vue";
import FooterItem from "@/components/layout/Footer.vue";

export default {
  name: "HomeId",
  components: {
    NavbarItem,
    HeroItem,
    StoryItem,
    FacilitiesItem,
    AccommodationItem,
    MiceItem,
    LocalAreaItem,
    ContactItem,
    SocialMediaGalleryItem,
    FooterItem,
  },
};
</script>