<template>
  <!-- MICE Redefined -->
  <section class="mice" id="mice">
    <img
      class="logo lazyload"
      src="images/logo-white-20.webp"
      alt="logo_white_next_hotel"
    />
    <h2 class="display2">MICE Redefined</h2>
    <div v-if="isLoading" class="skeleton-loading"></div>
    <div v-for="(data, index) in data.data" :key="index" v-else>
      <div class="row g-0 mt-6">
        <div class="col-lg-6">
          <div class="highlight">
            <img
              :src="data.mice_image_left"
              alt="meeting"
              class="w-100 lazyload"
            />
            <div class="overlay"></div>
            <div class="title">
              <h3 v-if="isEnglish">Meeting</h3>
              <h3 v-else>{{ data.header_left }}</h3>
              <div
                class="d-flex align-items-center justify-content-between mt-3"
              >
                <p class="h6 text-white fw-400 w-100" v-if="isEnglish">
                  There are 5 meeting rooms to meet your specific business
                  needs, from a smaller one on the Ground floor with capacity of
                  10 pax to the ballroom on the 3rd floor with capacity of 250
                  pax.
                </p>
                <p class="h6 text-white fw-400 w-100" v-else>
                  {{ data.desc_left }}
                </p>
                <!-- <a href="assets/file/sample.pdf" class="btn btn-primary" download
                ><span class="material-icons-round">download</span
                ><span class="btn-ms">Unduh Brosur</span></a
              > -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="highlight">
            <img
              :src="data.mice_image_right"
              alt="weeding"
              class="w-100 lazyload"
            />
            <div class="overlay"></div>
            <div class="title">
              <h3 v-if="isEnglish">Wedding</h3>
              <h3 v-else>{{ data.header_right }}</h3>
              <div
                class="d-flex align-items-center justify-content-between mt-3"
              >
                <p class="h6 text-white fw-400 w-100" v-if="isEnglish">
                  Celebrate your intimate wedding moment at our modern function
                  room or outdoor pool area that can accommodate up to 250
                  people.<br /><br />
                </p>
                <p class="h6 text-white fw-400 w-100" v-else>
                  {{ data.desc_right }}<br /><br />
                </p>
                <!-- <a href="assets/file/sample.pdf" class="btn btn-primary" download
                ><span class="material-icons-round">download</span
                ><span class="btn-ms">Unduh Brosur</span></a
              > -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End MICE -->
</template>

<script>
import axios from "axios";

export default {
  name: "MiceItem",

  data() {
    return {
      data: [],
      isLoading: true,
    };
  },
  methods: {
    setData(data) {
      this.data = data;
      this.isLoading = false;
    },
  },
  mounted() {
    // Check if data is already in local storage
    const miceData = localStorage.getItem("miceData");

    if (miceData) {
      // Use stored data if available
      this.setData(JSON.parse(miceData));
    } else {
      // If not available, make API call
      axios
        .get("https://nexthotel.id/cms/api/mice-api")
        .then((res) => {
          this.setData(res.data);

          // Store the data in local storage for future use
          localStorage.setItem("miceData", JSON.stringify(res.data));
        })
        .catch((error) => {
          // handle error
          console.log("Gagal", error);
          this.isLoading = false;
        });
    }
  },
  computed: {
    isEnglish() {
      return (
        this.$route.path.includes("/en") || this.$route.path.includes("/en/")
      );
    },
  },
};
</script>

<style scoped>
.skeleton-loading {
  width: 100%;
  height: 100vh;
  background: linear-gradient(90deg, #f0f0f0 25%, #d0d0d0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: gradient-slide 1.5s infinite;
}

@keyframes gradient-slide {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
</style>
