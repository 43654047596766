<template>
  <!-- Footer -->
  <section
    class="footer"
    style="background: var(--dark) url(/images/footer-texture.webp)"
  >
    <div class="container">
      <div class="row d-flex align-items-center">
        <div class="col-lg-3">
          <v-lazy-image
            class="logo"
            src="/images/logo.png"
            alt="logo_next_hotel"
            width="140"
            height="57"
          />
        </div>
        <div class="col-lg-9 d-flex justify-content-end">
          <ul v-if="isEnglish">
            <li><a href="#story">Story</a></li>
            <li><a href="#facilities">Facilities</a></li>
            <li><a href="#accommodation">Accommodation</a></li>
            <li><a href="#mice">MICE</a></li>
            <li><a href="#local-area">Local Area</a></li>
            <li>
              <div class="socmed">
                <a href="https://instagram.com/next.hotel.id" target="_blank"
                  ><v-lazy-image
                    src="/images/socmed-instagram.webp"
                    alt="Instagram"
                    width="25"
                    height="20"
                /></a>
                <a href="https://web.facebook.com/next.hotel.id" target="_blank"
                  ><v-lazy-image
                    src="/images/socmed-facebook.webp"
                    alt="Facebook"
                    width="25"
                    height="20"
                /></a>
              </div>
            </li>
          </ul>
          <ul v-else>
            <li><a href="#story">Stori</a></li>
            <li><a href="#facilities">Fasilitas</a></li>
            <li><a href="#accommodation">Akomodasi</a></li>
            <li><a href="#mice">MICE</a></li>
            <li><a href="#local-area">Area Lokal</a></li>
            <li>
              <div class="socmed">
                <a href="https://instagram.com/next.hotel.id" target="_blank"
                  ><v-lazy-image
                    src="/images/socmed-instagram.webp"
                    alt="Instagram"
                    width="25"
                    height="20"
                /></a>
                <a href="https://web.facebook.com/next.hotel.id" target="_blank"
                  ><v-lazy-image
                    src="/images/socmed-facebook.webp"
                    alt="Facebook"
                    width="25"
                    height="20"
                /></a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="divider"></div>
    </div>
    <div class="credit">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <span class="body text-primary fw-400">
              © {{ currentYear }} Next Hotel. All Right Reserved.
            </span>
          </div>
          <div class="col-lg-6 d-flex align-items-center justify-content-end">
            <span class="body text-primary fw-400" v-if="isEnglish">
              Managed by MNC Hotel, part of MNC Land
            </span>
            <span class="body text-primary fw-400" v-else>
              Dikelola oleh MNC Hotel, bagian dari
            </span>
            <a href="https://www.mncland.com/" target="_blank">
              <v-lazy-image
                class="ms-2"
                src="/images/logo-mnc-land.webp"
                alt="MNC Land"
                width="57"
                height="25"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Footer -->
</template>

<script>
import VLazyImage from "v-lazy-image";

export default {
  name: "FooterItem",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  components: {
    VLazyImage,
  },
  computed: {
    isEnglish() {
      return (
        this.$route.path.includes("/en") || this.$route.path.includes("/en/")
      );
    },
  },
};
</script>

<style scoped>
.footer .socmed a img {
  object-fit: contain;
}
</style>
