<template>
  <div class="home">
    <keep-alive>
      <router-view />
    </keep-alive>
    <a href="https://wa.me/628111869888" target="_blank" class="btn btn-wa"
      ><img src="/images/whatsapp.svg" alt="WhatsApp" width="40" height="40"
    /></a>
  </div>
</template>

<script>
export default {
  name: "HomeId",
  mounted() {
    window.addEventListener("scroll", this.adjustButtonPosition);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.adjustButtonPosition);
  },
  methods: {
    adjustButtonPosition() {
      const button = document.querySelector(".btn-wa");
      const footer = document.querySelector(".footer");
      const scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      const footerPosition = footer.offsetTop;

      if (scrollPosition + window.innerHeight >= footerPosition) {
        button.style.bottom = "164px";
        button.style.transition = "bottom 0.9s ease"; // Add a smooth transition
      } else {
        button.style.position = "fixed";
        button.style.bottom = "24px";
      }
    },
  },
};
</script>