<template>
  <!-- Contact Us -->
  <section class="contact" id="contactus">
    <img
      class="logo lazyload"
      src="images/logo-gold-20.webp"
      alt="logo_gold_next_hotel"
    />
    <div class="container">
      <h2 class="display2 text-dark" v-if="isEnglish">Contact Us</h2>
      <h2 class="display2 text-dark" v-else>Hubungi Kami</h2>
      <div class="row mt-6">
        <div class="col-lg-5">
          <div class="maps">
            <div v-if="isLoading" class="skeleton-loading"></div>
            <div v-for="(dataMaps, index) in dataMaps.data" :key="index" v-else>
              <div v-html="dataMaps.embed_code" title="Maps"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-7">
          <div v-if="isLoading" class="skeleton-loading"></div>
          <div class="box" v-else>
            <div v-for="(dataContact, index) in dataContact.data" :key="index">
              <div>
                <h2>NEXT HOTEL YOGYAKARTA</h2>
                <h6 class="mt-4">
                  <div v-html="dataContact.address"></div>
                </h6>
                <ul class="mt-5">
                  <li class="d-flex align-items-center">
                    <span v-if="isEnglish">phone</span>
                    <span v-else>telp</span>
                    <span style="margin-left: 40px" v-if="isEnglish">
                      : &nbsp;
                    </span>
                    <span style="margin-left: 60px" v-else> : &nbsp; </span>
                    <span><div v-html="dataContact.phone"></div></span>
                  </li>
                  <li class="d-flex align-items-center">
                    <span>whatsapp</span>
                    <span style="margin-left: 10px"> : &nbsp; </span>
                    <span><div v-html="dataContact.wa"></div></span>
                  </li>
                  <li class="d-flex align-items-center">
                    <span>email&nbsp;</span>
                    <span style="margin-left: 41px"> : &nbsp;</span>
                    <span><div v-html="dataContact.email"></div></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Contact Us -->
</template>

<script>
import axios from "axios";
export default {
  name: "ContactItem",
  data() {
    return {
      dataMaps: [],
      dataContact: [],
      isLoading: true,
    };
  },
  methods: {
    setDataMaps(data) {
      this.dataMaps = data;
      this.isLoading = false;
    },
    setDataContact(data) {
      this.dataContact = data;
      this.isLoading = false;
    },
  },
  mounted() {
    // Check if data for "location-api" is already in local storage
    const locationData = localStorage.getItem("locationData");

    if (locationData) {
      // Use stored data if available
      this.setDataMaps(JSON.parse(locationData));
    } else {
      // If not available, make API call
      axios
        .get("https://nexthotel.id/cms/api/location-api")
        .then((res) => {
          this.setDataMaps(res.data);

          // Store the data in local storage for future use
          localStorage.setItem("locationData", JSON.stringify(res.data));
        })
        .catch((error) => {
          // handle error
          console.log("Gagal", error);
          this.isLoading = false;
        });
    }

    // Check if data for "contact-api" is already in local storage
    const contactData = localStorage.getItem("contactData");

    if (contactData) {
      // Use stored data if available
      this.setDataContact(JSON.parse(contactData));
    } else {
      // If not available, make API call
      axios
        .get("https://nexthotel.id/cms/api/contact-api")
        .then((res) => {
          this.setDataContact(res.data);

          // Store the data in local storage for future use
          localStorage.setItem("contactData", JSON.stringify(res.data));
        })
        .catch((error) => {
          // handle error
          console.log("Gagal", error);
          this.isLoading = false;
        });
    }
  },
  computed: {
    isEnglish() {
      return (
        this.$route.path.includes("/en") || this.$route.path.includes("/en/")
      );
    },
  },
};
</script>

<style scoped>
.mapouter {
  position: relative;
  text-align: right;
  height: 500px;
  width: 600px;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 500px;
  width: 600px;
}

.skeleton-loading {
  width: 100%;
  height: 100vh;
  background: linear-gradient(90deg, #f0f0f0 25%, #d0d0d0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: gradient-slide 1.5s infinite;
}

@keyframes gradient-slide {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
</style>
