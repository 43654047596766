import { createRouter, createWebHistory } from 'vue-router'
import HomeId from '@/views/id/Index.vue'
import HomeEn from '@/views/en/Index.vue'
import PageNotFound from '@/views/PageNotFound.vue'

const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'HomeId',
            component: HomeId
        },
        {
            path: '/en',
            name: 'HomeEn',
            component: HomeEn
        },
        {
            path: '/:catchAll(.*)',
            name: 'Error404',
            component: PageNotFound
        },
    ]
})

export default router