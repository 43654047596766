<template>
  <!-- Navbar -->
  <nav
    class="navbar navbar-expand-lg"
    style="background-image: url('images/texture.webp') !important"
  >
    <div class="container">
      <a class="navbar-brand" href="#">
        <v-lazy-image
          class="logo"
          :src="logo"
          src-placeholder="images/logo.png"
          alt="Logo"
          width="180"
          height="73"
        />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="showForNavbar"
      >
        <span class="material-icons-round">menu</span>
      </button>
      <div class="navbar-collapse" v-show="showNavbar">
        <ul class="navbar-nav ms-auto" v-if="isEnglish">
          <li class="nav-item">
            <a class="nav-link scroll" href="#story">Story</a>
          </li>
          <li class="nav-item">
            <a class="nav-link scroll" href="#facilities">Facilities</a>
          </li>
          <li class="nav-item">
            <a class="nav-link scroll" href="#accommodation">Accommodation</a>
          </li>
          <li class="nav-item">
            <a class="nav-link scroll" href="#mice">MICE</a>
          </li>
          <li class="nav-item">
            <a class="nav-link scroll" href="#local-area">Local Area</a>
          </li>
          <li class="nav-item">
            <a
              href="https://www.e1-booking.com/booking/next-hotel-yogyakarta_2737.html"
              class="btn btn-primary"
              target="_blank"
              >Book Now</a
            >
          </li>
          <li class="nav-item">
            <div class="list-language">
              <router-link to="/" class="language" active-class="active"
                >ID</router-link
              >
              <router-link to="/en" class="language" active-class="active"
                >EN</router-link
              >
            </div>
          </li>
        </ul>
        <ul class="navbar-nav ms-auto" v-else>
          <li class="nav-item">
            <a class="nav-link scroll" href="#story">Stori</a>
          </li>
          <li class="nav-item">
            <a class="nav-link scroll" href="#facilities">Fasilitas</a>
          </li>
          <li class="nav-item">
            <a class="nav-link scroll" href="#accommodation">Akomodasi</a>
          </li>
          <li class="nav-item">
            <a class="nav-link scroll" href="#mice">MICE</a>
          </li>
          <li class="nav-item">
            <a class="nav-link scroll" href="#local-area">Area Lokal</a>
          </li>
          <li class="nav-item">
            <a
              href="https://www.e1-booking.com/booking/next-hotel-yogyakarta_2737.html"
              class="btn btn-primary"
              target="_blank"
              >Book Now</a
            >
          </li>
          <li class="nav-item">
            <div class="list-language">
              <router-link to="/" class="language active">ID</router-link>
              <router-link to="/en" class="language">EN</router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- End Navbar -->
</template>

<script>
import axios from "axios";
import VLazyImage from "v-lazy-image";

export default {
  name: "LayoutNavbar",
  components: {
    VLazyImage,
  },
  data() {
    return {
      data: [],
      logo: "",
      modal_book: false,
      showNavbar: false,
    };
  },

  methods: {
    setData(data) {
      this.data = data;
    },
    showModalBook() {
      this.modal_book = !this.modal_book;
    },
    showForNavbar() {
      this.showNavbar = !this.showNavbar;
    },
  },
  mounted() {
    // Get Api From Navbar
    axios
      .get("https://nexthotel.id/cms/api/get-brand")
      .then((res) => {
        this.logo = res.data.data.logo;
      })
      .catch(function (error) {
        console.log("Gagal", error);
      });
  },
  computed: {
    isEnglish() {
      return (
        this.$route.path.includes("/en") || this.$route.path.includes("/en/")
      );
    },
  },
};
</script>